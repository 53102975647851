import React, {useState} from "react"
import Layout from "../components/layout"
import Modal from 'react-modal'
import Apartman_1 from './apartmany/1'
import Apartman_3 from './apartmany/3'
import Apartman_4 from './apartmany/4'
import Apartman_5 from './apartmany/5'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'


const customStyles = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '800px',
    maxHeight: '88%',
    // filter: 'blur(8px)'
    padding: '20px',
    // background: 'rgba(49, 49, 49, 0.5)'
    boxShadow: '5px 10px 18px #888888',
  }
};



export default function Apartmany() {


  // var subtitle;
  // const [modalIsOpen, setIsOpen]= React.useState(false);

  // function openModal() {
  //   setIsOpen(true);
  // }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  // function closeModal(){
  //   setIsOpen(false);
  // }


  const [modal1, setModal1] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [modal5, setModal5] = useState(false);



  const modalAction1 = () => setModal1(!modal1)
  const modalAction3 = () => setModal3(!modal3)
  const modalAction4 = () => setModal4(!modal4)
  const modalAction5 = () => setModal5(!modal5)




  return (
    
  <Layout>

    <div className='page-block circle-block' style={{marginTop: `30px`}}>

    <div>
    <div onClick={modalAction5} className='circle'>
    <p className='circle-title'>Apartmán<br/>5</p>
    <p className='circle-subtitle'>1 - 5 osôb</p>
  </div>
    <Modal
      isOpen={modal5}
      onAfterOpen={afterOpenModal}
      onRequestClose={modalAction5}
      style={customStyles}
      contentLabel="Apartmán č. 5"
    >
      <div onClick={modalAction5}> 
        <FontAwesomeIcon className='modal-close' icon={faTimes} />
      </div>
      <Apartman_5 />
    </Modal>
  </div>



        <div>
        <div onClick={modalAction4} className='circle'>
        <p className='circle-title'>Apartmán<br/>4</p>
        <p className='circle-subtitle'>1 - 5 osôb</p>
      </div>
        <Modal
          isOpen={modal4}
          onAfterOpen={afterOpenModal}
          onRequestClose={modalAction4}
          style={customStyles}
          contentLabel="Apartmán č. 4"
        >
          <div onClick={modalAction4}> 
            <FontAwesomeIcon className='modal-close' icon={faTimes} />
          </div>
          <Apartman_4 />
        </Modal>
      </div>



        <div>
        <div onClick={modalAction3} className='circle'>
        <p className='circle-title'>Apartmán<br/>3</p>
        <p className='circle-subtitle'>1 - 5 osôb</p>
      </div>
        <Modal
          isOpen={modal3}
          onAfterOpen={afterOpenModal}
          onRequestClose={modalAction3}
          style={customStyles}
          contentLabel="Apartmán č. 3"
        >
          <div onClick={modalAction3}> 
            <FontAwesomeIcon className='modal-close' icon={faTimes} />
          </div>
          <Apartman_3 />
        </Modal>
      </div>



      <div>
        <div onClick={modalAction1} className='circle'>
        <p className='circle-title'>Apartmán<br/>1</p>
        <p className='circle-subtitle'>1 - 4 osoby</p>
      </div>
        <Modal
          isOpen={modal1}
          onAfterOpen={afterOpenModal}
          onRequestClose={modalAction1}
          style={customStyles}
          contentLabel="Apartmán č. 1"
        >
          <div onClick={modalAction1}> 
            <FontAwesomeIcon className='modal-close' icon={faTimes} />
          </div>
          <Apartman_1 />
        </Modal>
      </div>





    </div>

  </Layout>
  );
}
    
  