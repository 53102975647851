import React from "react"
import '../styles/footer.css'


export default function Footer() {


  return (
    <div className='footer-block'>
        <div className='footer-line'/>
        <p style={{marginLeft: `5px`}}>Nábrežie arm. gen. L. Svobodu 40, 81102 Bratislava</p>
    </div>

  );
}