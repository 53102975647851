import React from "react"
import "react-image-gallery/styles/css/image-gallery.css"
import Apartment from '../../components/apartment'


export default function Apartman_1() {
  

  const images = [
    {
      original: '/photos/A1_1000_1.png',
      thumbnail: '/photos/A1_250_1.png',
    },
    {
      original: '/photos/A1_1000_2.png',
      thumbnail: '/photos/A1_250_2.png',
    },
    {
      original: '/photos/A1_1000_3.png',
      thumbnail: '/photos/A1_250_3.png',
    }
  ];

  let description = 'Tento apartmán má kompletne vybavenú kuchyňu prepojenú s obývacím priestorom s rozkladacou pohovkou a TV, oddelenú spálňu a kúpeľňu so sprchovacím kútom a WC. Apartmán sa nachádza v suteréne s malými oknami.'
  let equipement = ['Posteľná bielizeň', 'Skriňa', 'Klimatizácia', 'Dlažba', 'Kúrenie', 'Chladnička', 'Mikrovlnná rúra', 'Kuchynský riad', 'Kuchynka', 'Rýchlovarná kanvica', 'Kávovar', 'Sporák', 'Pohovka', 'Priestor na posedenie', 'TV s plochou obrazovkou', 'Stojan na oblečenie', 'Sušiak na bielizeň', 'Rozkladacia pohovka', 'Toaleta', 'Sprchovací kút', 'Uteráky', 'Toaletný papier']

  return (
    <Apartment
    title='Apartmán 1'
    images={images}
    size='35'
    description={description}
    equipement={equipement}/>

  );
}
    
  