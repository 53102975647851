import React from "react"
import { Link } from "gatsby"
import '../styles/sideMenu.css'
import { globalHistory } from "@reach/router"


export default function SideMenu() {

   const openNav = () => ( 
    document.getElementById("mySidenav").style.width = "250px",
    document.getElementById("overlay").className = "overlay"
   )
  
  const closeNav = () => (
    document.getElementById("mySidenav").style.width = "0",
    document.getElementById("overlay").className = ""
  )

  const isActive = (path) => (
    globalHistory.location.pathname === path ? 'menu-item-active' : ''
  );

  return (
    <div>
        <div id="mySidenav" className="side-menu">

            <div role = "button" className="closebtn" onClick={closeNav} onKeyDown={closeNav} tabIndex={0}>&times;</div>
            <hr/>
            <Link className={`${isActive('/')}`} to="/">Úvod</Link>
            <hr/>
            <Link className={`${isActive('/apartmany/')}`} to="/apartmany/">Apartmány</Link>
            <hr/>
            <Link className={`${isActive('/gastro/')}`} to="/gastro/">Gastro</Link>
            <hr/>
            <Link className={`${isActive('/cennik/')}`} to="/cennik/">Cenník</Link>
            <hr/>
            <Link className={`${isActive('/kontakt/')}`} to="/kontakt/">Kontakt</Link>
            <hr/>
        </div>

        <div role = "button" className='side-menu-button' onClick={openNav} onKeyDown={closeNav} tabIndex={0}>&#9776;</div>
        <div id='overlay'/>
    
        </div>

  );
}