import React from "react"
import { Link } from "gatsby"
import SideMenu from './SideMenu'
import Footer from './footer'

import { globalHistory } from "@reach/router";



export default function Layout({ children }) {



  const isActive = (path) => (
    globalHistory.location.pathname === path ? 'menu-item-active' : ''
  );

  return (
    <div>

      <header >
        <SideMenu/>

        <div className='main-title'>LAFRANCONI</div>
          <div className='sub-title-block'>
          <div className='line-title'></div>
            <div className='sub-title'>Apartmány</div>
            <div className='line-title'></div>

        </div>

        <div className='menu-block'>
          <Link className={`menu-item ${isActive('/')}`} to="/">Úvod</Link>
          <Link className={`menu-item ${isActive('/apartmany/')}`} to="/apartmany/">Apartmány</Link>
          <Link className={`menu-item ${isActive('/gastro/')}`} to="/gastro/">Gastro</Link>
          <Link className={`menu-item ${isActive('/cennik/')}`} to="/cennik/">Cenník</Link>
          <Link className={`menu-item ${isActive('/kontakt/')}`} to="/kontakt/">Kontakt</Link>
        </div>

      </header>
      {children}
     
      <Footer/>
    </div>
  )
}